@import '../variables';
@import '../settings';

@import './planes-fijos-modal';

.has-bootstrap.planes-fijos {
  @import '../base/_slick-theme';

  a {
    text-decoration: none;
  }

  .will-change {
    will-change: contents;
  }

  .details-text-button {
    cursor: pointer;
    color: rgb(1, 151, 169);
    text-decoration: underline;
  }

  .roboto-black {
    font-family: $font-black !important;
  }

  .roboto-light {
    font-family: $font-light !important;
  }

  .roboto-medium {
    font-family: $font-medium !important;
  }

  .roboto-regular {
    font-family: $font !important;
  }

  .space-pre {
    white-space: pre-wrap;
  }

  .selection-title {
    color: #424242;
    font-size: 1.8rem;
    /* letter-spacing: -1px; */
    line-height: 1.5;
    font-weight: bold;
  }

  .selection-title span {
    color: #666666;
  }

  .grey--text {
    color: #666666 !important;
  }

  .red--text {
    color: #da291c !important;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn-fijo {
    border-radius: 2rem;
    font-size: .8rem;
    line-height: 1.5rem;
  }

  .red-claro-btn {
    background: $red;
    color: white;
    font-weight: 700;
  }

  .black--text {
    color: #222222 !important;
  }

  //.red-claro-btn span::after {
  //  content: ">";
  //  font-family: "Font Awesome 5 Free", sans-serif;
  //  display: inline-block;
  //  padding-left: 7px;
  //  transform: scale(0.6, 1.3);
  //  font-weight: 600;
  //}

  .red-claro-btn:hover {
    color: white;
  }

  .aviso {
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border-left: #c4c4c4 solid 16px;
    padding: 15px;
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;

    .fa-stack {
      font-size: .7rem;
      color: $blue;
    }

    .texto {
      font-size: .8rem;
    }

    a {
      text-decoration: underline;
    }
  }

  @include tablet() {
    .tl-products-1 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .mobile-carousel-item {
    /* max-width: 280px; */
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin: 0.5%;
    /* Hover */
    transition: all .5s ease-in-out;

    //> div {
    //  height: 100%;
    //
    //  .mobile-carousel-item {
    display: flex;
    flex-direction: column;
    cursor: grab;
    @include tablet() {
      height: 100%;
    }

    &.tl-selected {
      cursor: grabbing;
    }

    .mobile-img {
      display: flex;
      align-items: center;
      @include tablet() {
        flex-grow: 1;
      }
    }

    //  }
    //}
  }

  .mobile-carousel-item:hover {
    transform: scale(1.05);
  }

  .mobile-slider .main-title {
    color: #666666;
    font-size: 1.25rem;
    /* font-weight: 600; */
    //letter-spacing: -1px;
    padding: 10% 6% 0 6%;
    font-family: $font-medium;
    line-height: 1.5rem;
    height: 6rem;
  }

  .mobile-slider .mobile-img img {
    width: 80%;
    height: auto;
    margin: 0 auto;
  }

  .mobile-slider .price-cont {
    float: left;
    color: #666666;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.5rem;
    font-family: $font-medium;
    margin: 0 5%;
    letter-spacing: -1px;
    width: 90%;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;

    > span {
      font-weight: bold;
    }
  }

  .mobile-slider .price-cont .mobile-price-bottom {
    color: #666666;
    font-size: 0.8rem;
    letter-spacing: 0;
  }

  .mobile-slider .price-cont .mobile-price-bottom .mobile-price-full {
    color: #666666;
    font-size: 1rem;
    letter-spacing: 0;
    font-weight: 600;
  }

  .mobile-slider .price-cont .decimal {
    font-size: 1.4rem;
    align-self: start;
  }

  .mobile-slider .mobile-price {
    font-family: $font-medium;
    color: #da291c;
    font-size: 2rem;
    margin-top: -0.5rem;
    /* letter-spacing: -2px; */
    display: flex;
    line-height: 1;
    justify-content: center;
    align-items: center;
  }

  .mobile-slider .mobile-price .mobile-price-text {
    font-size: 1.3rem;
    font-family: $font-medium;
  }

  .mobile-slider .price-cont .mobile-colors {
    background: #585858;
    border-radius: 50%;
    float: right;
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }

  .mobile-slider .quetzal {
    //font-size: 1.6rem;
  }

  .btn-slider-buy {
    padding: 10%;

    > span {
      font-size: .8rem;
      text-decoration: underline;
      cursor: pointer;
      color: $blue;
      margin-block: 0 1rem;
    }
  }

  .cart-carousel .price-cont span {
    letter-spacing: 0;
  }

  /* New colors */
  .smooth-gray {
    color: #BBBBBB;
  }


  /* slick Slider adjustments */
  .slick-prev {
    left: -20px;
  }
  .slick-prev:before {
    content: '';
    display: inline-block;
    border-right: 5px solid #b4b6b7;
    border-bottom: 5px solid #b4b6b7;
    width: 35px;
    height: 35px;
    transform: rotate(-225deg);
  }

  [dir='rtl'] .slick-prev:before {
    content: '';
    display: inline-block;
    border-right: 5px solid #b4b6b7;
    border-bottom: 5px solid #b4b6b7;
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
  }

  .slick-next {
    right: -5px;
  }
  .slick-next:before {
    content: '';
    display: inline-block;
    border-right: 5px solid #b4b6b7;
    border-bottom: 5px solid #b4b6b7;
    width: 35px;
    height: 35px;
    transform: rotate(-45deg);
  }

  [dir='rtl'] .slick-next:before {
    content: '';
    display: inline-block;
    border-right: 5px solid #b4b6b7;
    border-bottom: 5px solid #b4b6b7;
    width: 35px;
    height: 35px;
    transform: rotate(-225deg);
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
  .circle-border {
    display: inline-block;
    color: black;
    font: 18px sans-serif;
    width: 16px;
    height: 16px;
    background-color: #d9d9d9 !important;
    border-radius: 10px; /* half of width + borders */
    line-height: 20px; /* vertical center */
    text-align: center; /* horizontal center */
  }

  .slick-active {
    .circle-border {
      background-color: #f3d95a !important;
    }
  }


  .customize-step {
    border: 1px solid #0197a9;
    background-color: #0197a9;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    font-size: 1.5rem;
    color: white;
    line-height: 1.5;
  }


  .check-cont label input {
    display: none;
  }

  .check-cont label {
    display: inline-block;
    cursor: pointer;
    width: auto;
    border-radius: 99px;
    text-align: center;
    line-height: 2;

    span {
      vertical-align: middle;
      margin-left: 6px;
    }
  }
  .check-cont .tl-selected {
    background-color: $blue;
  }

  .check-cont label:hover i {
    border-color: $blue;
  }

  .check-cont label input:checked + i {
    border-color: rgba(255, 255, 255, 0.587);
  }

  .check-cont label i {
    line-height: 1.7;
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 99px;
    border: 2px solid #f2f2f2;
    transition: all .3s ease;
    vertical-align: middle;
  }

  .check-cont label i span {
    border-radius: 99px;
    width: 18px;
    height: 18px;
    // box-shadow: inset 0 0 1px #333;
    position: absolute;
    margin: 1px -9px;
  }

  .color-gris {
    background-color: grey;
  }

  .color-negro {
    background-color: #000;
  }

  /* Classes adjustments */
  .main-title {
    div {
      font-size: 1rem;
      font-weight: 600;
      font-family: $font-light;
      &:first-child {
        font-size: 1.3rem;
        font-family: $font;
      }
    }
    .modalidad {
      font-size: .95rem;
      font-weight: 400;
    }

    font-weight: 600;
  }

  .cart-carousel .mobile-img img {
    width: 80%;
    margin-top: 2%;
  }

  .mobile-slider .main-title {
    height: auto;
    padding: 4% 4% 0 4%
  }

  .mobile-slider .mobile-price {
    margin-top: 0;
  }

  .btn-slider-buy {
    padding: 0 10% 4% 10%;
  }

  .mobile-slider .slick-slide {
    max-width: 280px;
  }

  @media screen and (max-width: 1400px) {
    .mobile-slider .slick-slide {
      max-width: 100%;
      margin: 0.5%;
    }
  }

  @media screen and (max-width: 600px) {
    .selection-title {
      font-size: 18px;
    }
  }

  .form-check {
    letter-spacing: 0;
  }

  .form-check-input {
    background-color: #f6f6f6;
    font-size: 1rem;
  }

  .form-check-input:checked {
    background-color: #0197a9;
    border-color: #0197a9;
  }


  .total-cont h1 {
    line-height: 1.5rem;
  }

  .total-cont .price-cont {
    color: #666666;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: $font-medium;
    letter-spacing: -1px;
  }

  .total-cont .price-cont .decimal {
    font-size: .65em;
  }

  .total-cont .mobile-price {
    font-family: $font-medium;
    color: #da291c;
    font-size: 3.5rem;
    margin-top: 0;
    /* letter-spacing: -2px; */
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: baseline;
    @include phone {
      font-size: 2rem;
    }
  }

  .total-cont .quetzal {
    font-size: 2.5rem;
  }

  .total-cont .red-claro-btn {
    padding: 0.4rem 2.5rem;
  }

  .total-cont .red-claro-btn span::after {
    content: '';
  }

  .mobile-carousel-item.tl-selected {
    box-shadow: 2px 2px 7px $blue;
    border-radius: 15px;
  }

  /* MODAL */
  .modal-title {
    background: #0197a9;
  }

  .modal-body {
    padding: 0 1rem 1rem 1rem;
    max-width: calc(100vw - 1.5rem);
    @include tablet() {
      max-width: 900px;
    }
  }

  .modal-body img {
    width: 50%;
    max-width: 231px;
  }

  @media screen and (max-width: 575px) {
    .modal-body img {
      width: 50%;
    }
  }

  .modal-close {
    font-size: 2rem;
  }


  .modal-footer h1 {
    line-height: 1.5rem;
  }

  .modal-footer .price-cont {
    color: #666666;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 0.9rem;
    font-family: $font-medium;
    margin: 0 5%;
    letter-spacing: -1px;
    width: 90%;
  }

  .modal-footer .price-cont .decimal {
    font-size: 2.3rem;
  }

  .modal-footer .mobile-price {
    font-family: $font-medium;
    color: #da291c;
    font-size: 2.5rem;
    margin-top: 0;
    /* letter-spacing: -2px; */
  }

  .modal-footer .quetzal {
    font-size: 2rem;
  }

  .modal-footer .mobile-price .mobile-price-text {
    font-size: 1.5rem;
    font-family: $font-medium;
  }

  .modal-ubicacion {
    .titulo {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .titulo2 {
      font-size: 1rem;
      font-weight: bold;
    }

    .ui--notification p {
      margin-bottom: 0;
    }

    .paso1 {
      display: grid;
      gap: 1.5rem;

      .form {
        margin-top: 1rem;

        > label {
          font-size: .9rem;
          font-weight: bold;
        }

        .usar-ubicacion {
          font-size: .9rem;
          float: right;
          color: $blue;
          cursor: pointer;
        }

        form {
          margin-top: .5rem;
          position: relative;

          input {
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 2rem;
            padding-left: 20px;
            padding-right: 40px;
            font-size: 1rem;

            &.list:not(.fail) {
              border-radius: 1rem 1rem 0 0;
            }
          }

          button {
            position: absolute;
            right: 15px;
            top: 10px;
            width: 24px;
          }
        }

        .verificar {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: space-between;
          margin-top: 1rem;

          @include tablet() {
            flex-direction: row;
          }

          .fa-stack {
            display: inline-block;
            font-size: .7rem;
            color: $blue;
            cursor: pointer;
          }
        }
      }
      .botones {
        display: flex;
        gap: 1rem;
        justify-content: flex-start;
        flex-direction: column;

        .cancelar {
          background: transparent;
          border-color: #313131;
          min-width: 125px;
        }

        .confirmar {
          min-width: 150px;

          &:disabled {
            background-color: #bbbbbb;
            border-color: #bbbbbb;
          }
        }
      }

      @include tablet() {
        //grid-template-columns: repeat(2, 1fr);
        //.mapa {
        //  grid-row: 1 / 3;
        //  order: 1;
        //}
        //.ubicacion {
        //  align-self: end;
        //  order: 2;
        //}
        .botones-container {
          order: 3;
          .botones {
            flex-direction: row-reverse;
          }
        }
      }
    }

    .paso2 {
      .pretitulo {
        font-size: 1.5rem;
        font-weight: bold;
        opacity: .5;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #dfdfdf;
        @include tablet() {
          font-size: 1.2rem;
        }
      }
      .modal-aviso {
        font-size: .8rem;
      }
      .cambiar {
        text-decoration: underline;
        font-size: .9rem;
      }
      @include phone() {
        .titulo + div {
          text-align: center;
        }
      }
      @include tablet() {
        .FormData {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          justify-content: space-between;
          button {
            width: 45%;
          }
        }
        .modal-aviso {
          width: 55%;
        }
      }
    }

    .paso3 {
      > div {
        display: grid;
        justify-items: center;
        gap: 1rem;
        text-align: center;
        > img {
          width: 64px;
        }
        > p {
          font-size: 1.4rem;
          font-weight: bold;
        }

        > button {
          min-width: 50%;
          @include phone() {
            width: 100%;
          }
        }
      }
    }
  }

  .formulario {
    .main {
      &_boxTitle {
        width: 100%;
        height: 100px;

        h2 {
          font-size: 3rem;
          text-align: center;
          color: #DA291C;
          margin: 0 0 10px 0;
        }

        h5 {
          color: #666666;
          font-size: 1.5rem;
          text-align: center;
          font-weight: normal;
          margin: 0;

          span {
            color: #DA291C;
          }
        }
      }

      &_containerFlex {
        display: flex;
        margin: 1rem auto;
        justify-content: space-between;
        gap: 2rem;
        padding: 0;

        .FormData {
          &--inputs {
            width: 45%;
            display: flex;
            flex-direction: column;
            h3 {
              margin: 0 0 16px 0;
              font-size: 1.2rem;
              font-weight: bold;
            }

            .input-container {
              display: flex;

              & > div {
                width: 100%;
              }

              input,
              select {
                height: 40px;
                border: 1px solid #ccc;
                margin-bottom: 5px;
                border-radius: 12px;
                padding-left: 20px;
                font-size: 1rem;
              }

              input.error {
                border: 1px solid #dd2d20;
                padding-right: 40px;
              }

              input.error:required {
                background-color: rgba(pink, 0.2);
              }

              &::after {
                display: none;
                content: "\f057";
                font-family: "Font Awesome 5 Free", serif;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                color: $red;
                font-size: 1.75em;
                margin-top: .375rem;
                margin-left: -35px;
              }

              &.error::after {
                display: inline-block;
              }

            }

            .Form-inputName {
              margin-bottom: 0;
            }

            .labelName {
              margin-bottom: 20px;
              font-size: 0.9rem;
              margin-top: 8px;
            }

            .Form-inputNumber {
              margin-bottom: 0;
            }

            .labelNumber {
              margin-bottom: 20px;
              font-size: 0.9rem;
              margin-top: 8px;
            }

            label.error {
              color: $black;
            }
          }

          button {
            min-height: 40px;
            margin-top: 1rem;
          }
          &:invalid button {
            background-color: #bbbbbb;
            border-color: #bbbbbb;
          }
        }

        .sectionResume {
          width: 50%;
          display: flex;
          flex-direction: column;

          h3 {
            margin: 0 0 1rem 0;
            font-size: 1.2rem;
            font-weight: bold;
            padding-bottom: 15px;
            border-bottom: 3px solid #F4F4F4;
          }

          .noDropdown {
            border-bottom: 4px solid rgb(197, 197, 197);
            padding-bottom: 2rem;
            margin-bottom: 1.5rem;

            .servicesData {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 1.1rem;
              font-weight: bold;

              span {
                color: #DA291C;
              }
            }
          }

          .servicesTotal {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.1rem;
            font-weight: bold;

            span {
              color: #DA291C;
              font-size: 1.3rem;
            }
          }
        }
      }
    }

    .dropdown {
      border-bottom: 3px solid #F4F4F4;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      &__face {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        i {
          position: relative;
          margin: 0 1rem;

          &.angle-down::before {
            position: absolute;
            top: -6px;
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-left: 2px solid $black;
            border-bottom: 2px solid $black;
            transform: rotate(-45deg);
          }

          &.angle-up::before {
            position: absolute;
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-right: 2px solid $black;
            border-top: 2px solid $black;
            transform: rotate(-45deg);
          }
        }

        & + input + .dropdown__face {
          margin-top: 1rem;
        }
      }

      &__text {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0;
        flex-grow: 1;

        img {
          width: 20px;
          height: 20px;
          margin-left: 20px;
          cursor: pointer;
        }
      }

      &__number {
        min-width: 70px;
        text-align: right;
      }

      &__items {
        margin: 0;
        width: 98%;
        list-style-type: none;
        display: none;
        transition: all 2s;
        padding: 0;
        font-size: 1rem;
        line-height: 2;

        li {
          padding-left: 1rem;
          background-position: 0 8px;
          background-size: 1rem 1rem;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;

          i {
            color: #279dae;
          }

          span {
            margin-left: 5px;
          }
        }


        &::before {
          content: "";
          position: relative;
          bottom: 100%;
          right: 20%;
          height: 40px;
          width: 20px;
          transition: all 2s;
        }
      }

      .dropdown__items {

      }

      input {
        display: none;

        & + label i {
          &.angle-up {
            display: none;
          }
        }

        &:checked + label i {
          &.angle-up {
            display: block;
          }

          &.angle-down {
            display: none;
          }
        }

        &:checked ~ .dropdown__items {
          display: block;
        }
      }
    }

    @media only screen and (max-width: 760px) {
      .main_boxTitle {
        height: auto;
        margin-bottom: 2.5rem;

        h2 {
          font-size: 2.8rem;
        }

        h5 {
          font-size: 1.4rem;
        }
      }
      .main_containerFlex {
        flex-direction: column-reverse;
        margin: 0;
        gap: 0;
        padding: 0;
        justify-content: center;
        width: 100%;

        .FormData {
          &--inputs {
            width: 100%;

            .input-container {
              width: 100%;

              & > div {
                width: 100%;
              }
            }



            h3 {
              font-size: 1.4rem;
              text-align: left;
              font-weight: bold;
            }
          }
          button {
            width: 100%;
          }
        }

        .sectionResume {
          width: 100%;
          margin-block: 1.5rem;

          .dropdown__text img {
            position: absolute;
            right: 10px;
            top: 0;
          }

          h3 {
            font-size: 1.4rem;
            text-align: left;
            font-weight: bold;
          }

          p {
            font-size: 1rem;
          }

          span {
            padding-right: 10px;
          }
        }

      }
    }
  }
}

.modalfijo-popover-container {
  z-index: 2000;
  max-width: 400px;
  opacity: .8;

  .modal-popover-content {
    background-color: #1f1d1d;
    color: #ffffff;
    padding: 1rem;
    border-radius: 1rem;

    p {
      margin-bottom: 1rem;
      font-size: .9rem;
      font-weight: bold;
    }

    li {
      font-size: .8rem;
      list-style-type: decimal;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.pac-container {
    z-index: 2000;
}
